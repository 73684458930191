import React from 'react';

import { Commands } from '@lib/robo/commands';

import { Grid, Button, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Presets({ setCommand, updateText }) {
    return (
        <Accordion sx={{ mt: 2, borderLeft: '3px solid #e4e' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6'>Presets</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={2}>
                        <Typography variant='body1'>General</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Button variant="outlined" color='tertiary' size='small' sx={{ mr: 1 }} onClick={() => {
                            setCommand(Commands.CMD_CONFIGURATION.code);
                            updateText('');
                        }}>
                            Get Config
                        </Button>
                    </Grid>

                    <Grid item xs={2}>
                        <Typography variant='body1'>Triggers</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Button variant="outlined" color='tertiary' size='small' sx={{ mr: 1 }} onClick={() => {
                            setCommand(Commands.CMD_SET_BUTTON_TRIGGER.code);
                            updateText('0 0 0');
                        }}>
                            Button 0
                        </Button>

                        <Button variant="outlined" color='tertiary' size='small' onClick={() => {
                            setCommand(Commands.CMD_SET_BUTTON_TRIGGER.code);
                            updateText('1 1 0');
                        }}>
                            Button 1
                        </Button>
                    </Grid>

                    <Grid item xs={2}>
                        <Typography variant='body1'>Motors</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Button variant="outlined" color='tertiary' size='small' sx={{ mr: 1 }} onClick={() => {
                            setCommand(Commands.CMD_MOTOR_ANGLE.code);
                            updateText('1 0 0 5a 0');
                        }}>
                            Motor 0 CW
                        </Button>

                        <Button variant="outlined" color='tertiary' size='small' onClick={() => {
                            setCommand(Commands.CMD_MOTOR_ANGLE.code);
                            updateText('1 0 0 5a 1');
                        }}>
                            Motor 0 CCW
                        </Button>
                    </Grid>

                    <Grid item xs={2}>
                        <Typography variant='body1'>Sounds</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Button variant="outlined" color='tertiary' size='small' sx={{ mr: 1 }} onClick={() => {
                            setCommand(Commands.CMD_PLAY_SOUND.code);
                            updateText('0x00');
                        }}>
                            Sound 0
                        </Button>

                        <Button variant="outlined" color='tertiary' size='small' onClick={() => {
                            setCommand(Commands.CMD_PLAY_SOUND.code);
                            updateText('0x02');
                        }}>
                            Sound 2
                        </Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

export default Presets;
