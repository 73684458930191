import React from 'react'

import { Typography } from '@mui/material'

import {
  useGetFeatureByIdQuery as useGetFeatureByIdQueryG,
  useGetFeatureByNameQuery as useGetFeatureByNameQueryG,
  useGetFeaturesQuery as useGetFeaturesQueryG,
} from '@store/api/feature.api.graphql'

import { useGetFeatureByIdQuery, useGetFeaturesQuery, useGetFeatureByNameQuery } from '@store/api/feature.api'

const PlaygroundPage = () => {
  // rest
  let existing = useGetFeatureByIdQuery(1) // existing id
  let nonExisting = useGetFeatureByIdQuery(5) // non-existing id
  let byName = useGetFeatureByNameQuery('is_debug') // existing name
  let nonExistingByName = useGetFeatureByNameQuery('NONEXISTENT') // existing name
  let collection = useGetFeaturesQuery() // all

  // graphql
  let existingG = useGetFeatureByIdQueryG(1) // existing id
  let nonExistingG = useGetFeatureByIdQueryG(5) // non-existing id
  let byNameG = useGetFeatureByNameQueryG('is_debug') // existing name
  let nonExistingByNameG = useGetFeatureByNameQueryG('NONEXISTENT') // existing name
  let collectionG = useGetFeaturesQueryG() // all

  return (
    <>
      <Typography variant="h1">API compare</Typography>

      <table width={'100%'} cellSpacing={0}>
        <thead>
          <tr style={{ background: '#aaa' }}>
            <th>
              <Typography variant={'h3'}>REST</Typography>
            </th>
            <th>
              <Typography variant={'h3'}>GraphQL</Typography>
            </th>
          </tr>
        </thead>

        <tbody>
          {/* ====================================================================================== */}
          <tr>
            <td colSpan={2} style={{ background: '#e5e5e5' }}>
              <Typography variant={'h4'}>By ID</Typography>
            </td>
          </tr>

          {/* -------------------------------------------------------------------------------------- */}
          <tr>
            <td>{<pre>{JSON.stringify(existing, null, 2)}</pre>}</td>
            <td>{<pre>{JSON.stringify(existingG, null, 2)}</pre>}</td>
          </tr>

          {/* ====================================================================================== */}
          <tr>
            <td colSpan={2} style={{ background: '#e5e5e5' }}>
              <Typography variant={'h4'}>Error By ID</Typography>
            </td>
          </tr>
          {/* -------------------------------------------------------------------------------------- */}
          <tr>
            <td>{<pre>{JSON.stringify(nonExisting, null, 2)}</pre>}</td>
            <td>{<pre>{JSON.stringify(nonExistingG, null, 2)}</pre>}</td>
          </tr>

          {/* ====================================================================================== */}
          <tr>
            <td colSpan={2} style={{ background: '#e5e5e5' }}>
              <Typography variant={'h4'}>By Name</Typography>
            </td>
          </tr>
          <tr>
            <td>{<pre>{JSON.stringify(byName, null, 2)}</pre>}</td>
            <td>{<pre>{JSON.stringify(byNameG, null, 2)}</pre>}</td>
          </tr>

          {/* ====================================================================================== */}
          <tr>
            <td colSpan={2} style={{ background: '#e5e5e5' }}>
              <Typography variant={'h4'}>Non existing By Name</Typography>
            </td>
          </tr>
          <tr>
            <td>{<pre>{JSON.stringify(nonExistingByName, null, 2)}</pre>}</td>
            <td>{<pre>{JSON.stringify(nonExistingByNameG, null, 2)}</pre>}</td>
          </tr>

          {/* ====================================================================================== */}
          <tr>
            <td colSpan={2} style={{ background: '#e5e5e5' }}>
              <Typography variant={'h4'}>Collection</Typography>
            </td>
          </tr>
          <tr>
            <td>{<pre>{JSON.stringify(collection, null, 2)}</pre>}</td>
            <td>{<pre>{JSON.stringify(collectionG, null, 2)}</pre>}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default PlaygroundPage;
