import React from 'react'

import { Typography } from '@mui/material'


const RoboCode = () => {
  return (
    <>
      <Typography variant="h1">Robo Code</Typography>
    </>


  )
}

export default RoboCode
