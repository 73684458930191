import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'

import { Button, Popover, Box, Backdrop, Chip } from '@mui/material';

import { RoboClient } from '@lib/robo/robo-client';
import { CommandsEditor } from './commands-editor';
import { CommandsQueueProvider } from '@dev/providers/commands-queue-context'

import { Logo } from '@components/Logo/Logo';

const MultiClientEditor = ({ robos, client }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const [roboClients, setRoboClients] = useState([]);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const addRoboClient = (roboClient) => {
        const clients = roboClients;
        clients.push(roboClient);
        setRoboClients(clients);
    };

    const removeRoboClient = (id) => {
        setRoboClients(roboClients.filter((roboClient) => roboClient.id !== id));
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const buildCommand = (command, data) => {
        try {
            return RoboClient.staticBuildCommand(command, data);
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    useEffect(() => {
        // Don't create clients if the popover is closed
        if (!open) return;

        const roboClientsIds = roboClients.map(roboClient => roboClient.id);

        const clientsToAdd = robos.filter(id => !roboClientsIds.includes(id));
        const clientsToRemove = roboClients.filter(id => !robos.includes(id));

        clientsToAdd.forEach(id => {
            const roboClient = new RoboClient(client, id);
            addRoboClient(roboClient);
        });

        clientsToRemove.forEach(id => {
            removeRoboClient(id);
        });
    }, [robos, open]);

    return (
        <CommandsQueueProvider>
            <Button variant="outlined" fullWidth onClick={handleClick}>
                Multiclient
            </Button>

            <Backdrop open={open}>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}

                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}

                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                >
                    <Box p={2} maxWidth={600} width={600}>
                        <CommandsEditor
                            title={
                                <Chip
                                    avatar={<Logo width={"24"} height={"24"} variant={'live'} />}
                                    label={`Multiclient`}
                                    variant="contained"
                                    sx={{ height: '40px', textAlign: 'left', paddingLeft: '10px' }}
                                />
                            }

                            clients={roboClients}
                            targetClient={null}


                            buildCommand={buildCommand}

                            showPresets={true}
                            showQueue={true}
                            showCommandOptions={true}
                            showActions={true}

                            handleClose={handleClose}

                            mqttClient={client}
                            allowCustomClient={true}
                        />
                    </Box>
                </Popover>
            </Backdrop>
        </CommandsQueueProvider>
    );
};

// proptypes
MultiClientEditor.propTypes = {
    robos: PropTypes.array.isRequired,
    client: PropTypes.object.isRequired
};

export default MultiClientEditor;
