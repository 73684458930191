class QueueMessage {
    constructor(client, command, data, runTimes = 1, runOffset = 0, runInterval = 100) {
        this.id = Math.random().toString(36).substring(2, 15);
        this.client = client;
        this.command = command;
        this.data = data;
        this.runTimes = runTimes;
        this.runOffset = runOffset;
        this.runInterval = runInterval;
    }
}

export default QueueMessage;