import React, { useState, useEffect, useContext } from 'react'
import { useQueryParamString } from 'react-use-query-param-string';

import {
  Alert,
  AlertTitle,
  Button,
  Container,
  Grid,
  TextField,
  Autocomplete,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Chip
} from '@mui/material'

import { styled } from '@mui/material/styles'

import { GridBreak } from '@components/ui/grid-break'
import { Logo } from '@components/Logo/Logo';
import { navigate } from '@lib/utils/navigate';


import CopyIcon from '@mui/icons-material/CopyAll';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';

import Hex from '@dev/components/hex'
import MessageEditor from '@dev/components/message-editor'
import RoboClientEditor from '@dev/components/robo-client-editor'
import MultiClientEditor from '@dev/components/multi-client-editor';

import { MQTTClient } from '@lib/mqtt/mqtt-client'

import { Commands } from '@lib/robo/commands'

import { MqttPlaygroundContext } from '@dev/providers/mqtt-playground-context'

const MqttPage = () => {

  const predefinedHosts = [
    'devices.robowunderkind.com',
    'jon-1.devices.robowunderkind.com',
    'anton-1.devices.robowunderkind.com',
    'genet-1.devices.robowunderkind.com',
    'ivan-1.devices.robowunderkind.com',
  ]; // Add more predefined IPs as needed

  const [cleanSession, setCleanSession] = useState(false);
  const [reconnect, setReconnect] = useState(true);
  const [reconnectInterval, setReconnectInterval] = useState(5000);
  const [maxReconnectAttempts, setMaxReconnectAttempts] = useState(3);
  const [timeout, setTimeout] = useState(10);
  const [keepAliveInterval, setKeepAliveInterval] = useState(5);


  const [scheme, setScheme] = useQueryParamString('scheme', 'wss', true);
  const [host, setHost] = useQueryParamString('host', 'devices.robowunderkind.com', true);
  const [port, setPort] = useQueryParamString('port', '443', true);
  const [path, setPath] = useQueryParamString('path', 'mqtt', true);
  const [user, setUser] = useQueryParamString('user', 'ROBO_BRIDGE', true);
  const [password, setPassword] = useQueryParamString('password', '8DF78WUH23R', true);


  const [client, setClient] = useState(null);

  // Playground specific states
  const [connected, setConnected] = useState(false);
  const [message, setMessage] = useState('STATUS_LIST');
  const [topic, setTopic] = useState('robo/list/get');

  const [subscribeTopic, setSubscribeTopic] = useState('#');
  const [subscriptions, setSubscriptions] = useState({});

  const [copied, setCopied] = useState(false);
  const [events, setEvents] = useState({});

  const dsn = `${scheme}://${host}:${port}/${path}`;

  // Robo specific states
  const [roboList, setRoboList] = useState([]);

  const {
    enableEvents, setEnableEvents,
    enableRichEvents, setEnableRichEvents,
    renderEvents, setRenderEvents,


  } = useContext(MqttPlaygroundContext);

  const AutocompleteTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      padding: 0
    },
  }))

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopied(true);
      })
      .catch(err => {
        console.error('Error in copying text: ', err);
      });
  };

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 300);
      return () => clearTimeout(timer); // This is to clean up the timer when the component is unmounted
    }
  }, [copied]);

  // ------------------ Events --------------------------------------------------------------
  // create addEvent function to add events to the list
  const addEvent = (type, message, extra, title, icon) => {
    if (!enableEvents) {
      return;
    }

    // random id to be used as key
    const id = Math.random().toString(16).substr(2, 8);

    setEvents((prevEvents) => {
      return { ...prevEvents, [id]: { type, message, date: new Date(), extra, title, icon } };
    });
  }

  const addError = (message, extra, title, icon) => {
    addEvent('error', message, extra, title, icon);
  }

  const addSuccess = (message, extra, title, icon) => {
    addEvent('success', message, extra, title, icon);
  }

  const addInfo = (message, extra, title, icon) => {
    addEvent('info', message, extra, title, icon);
  }

  const clearEvents = () => {
    setEvents({});
  }

  // ------------------ Subscriptions -----------------------------------------------------------
  const addSubscription = (topic) => {
    addInfo(`Subscribing to topic: ${topic}`);
    client.subscribe(topic);
  }

  const removeSubscription = (topic) => {
    addInfo(`Unsubscribing from topic: ${topic}`);
    client.unsubscribe(topic);
  }

  // ------------------ MQTT Client handlers------------------

  // Called when the client connects
  function onConnected() {
    enableEvents && addSuccess('Connected to MQTT broker', null, null, <CheckCircleIcon />);
    setConnected(true);
  }

  function onDisconnected() {
    enableEvents && addInfo('Disconnected from MQTT broker');
    setConnected(false);
    setRoboList([]);
  }

  function onConnectionFailure(client) {
    enableEvents && addError('Failed to connect to MQTT broker');
    setConnected(false);
  }

  // Called when the client loses its connection
  function onConnectionLost(client, responseObject) {
    enableEvents && addError(`Connection lost. Error: ${responseObject.errorMessage}`);
  }

  // Called when a message arrives
  function onMessageArrived(client, message) {

    try {
      if (enableEvents) {
        // if the message topic is in the array of text topics, extract text
        const textTopics = [
          'robo/list',
          'robo/list/get',
        ]

        const extractText = textTopics.includes(message.topic);
        const extra = <Hex data={message.payloadBytes} columns={10} extractText={extractText} />

        addSuccess(`Message Arrived from: "${message.topic}"`, extra, highlightCommand('Arrived', message.topic, message.payloadBytes), <DownloadIcon />);
      }

      route(message);
    } catch (err) {
      console.error('Error in onMessageArrived: ', err);
    }
  }

  // Called when a message is delivered
  const onMessageDelivered = (client, message) => {
    if (!enableEvents) {
      return;
    }

    // if the message topic is in the array of text topics, extract text
    const textTopics = [
      'robo/list',
      'robo/list/get',
    ]

    const extractText = textTopics.includes(message.topic);
    const extra = <Hex data={message.payloadBytes} columns={10} extractText={extractText} />

    addSuccess(`Message Delivered to: "${message.topic}"`, extra, highlightCommand('Delivered', message.topic, message.payloadBytes), <UploadIcon color='warning' />);
  }

  function onSubscribe(client) {
    setSubscriptions({ ...client.getSubscriptions() });
  }

  function onUnsubscribe(client) {
    setSubscriptions({ ...client.getSubscriptions() });
  }

  const highlightCommand = (message, topic, data) => {
    // Check if data has at least one byte
    if (data.length === 0) {
      return message;
    }

    let commandByte = null;

    if (topic.endsWith('/transmit')) {
      // For arrived message, take the first byte as a Command
      commandByte = data[0];
    } else if (topic.endsWith('/receive')) {
      // For delivered message, take the second byte as a Command
      // Check if data has at least two bytes
      if (data.length < 2) {
        return message;
      }
      commandByte = data[1];
    } else {
      return message;
    }


    // Get command name by its value from Commands enum
    const commandName = Object.keys(Commands).find(key => Commands[key].code === commandByte);
    return commandName ? <>{message} <Chip label={commandName} /></> : <>{message} <Chip label={'UNKNOWN COMMAND'} color='error' /></>;
  }

  // ------------------ Route responses ------------------
  const route = (message) => {
    switch (message.topic) {
      case 'robo/list/get':
        break;

      case 'robo/list':
        const roboList = message.payloadString.split(',').filter(roboName => !!roboName).map(roboName => roboName.split(':')[0]);
        setRoboList(roboList);
        break;

      default:
      // console.log('Unrouted topic in message ', message);
    }
  }

  // ------------------ MQTT Client ------------------
  const handleConnectClick = () => {
    addInfo('Connecting to MQTT broker');

    const options = {
      url: `${scheme}://${host}:${port}/${path}`,
      username: user,
      password: password,
      clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8),

      debug: true, // only in playground
    }

    // todo To be set on found good values
    // const connectionOptions = {
    //   reconnect: true,
    //   reconnectInterval: 5000,
    //   maxReconnectAttempts: 3,
    //   timeout: 10,
    //   keepAliveInterval: 5,
    //   cleanSession: false,
    // }

    const connectionOptions = {
      reconnect,
      reconnectInterval,
      maxReconnectAttempts,
      timeout,
      keepAliveInterval,
      cleanSession,
    }

    const mqttClient = new MQTTClient(options, connectionOptions);

    mqttClient.onConnected(onConnected);
    mqttClient.onConnectionLost(onConnectionLost)
    mqttClient.onConnectionFailure(onConnectionFailure);
    mqttClient.onDisconnected(onDisconnected);

    mqttClient.onMessageArrived(onMessageArrived);
    mqttClient.onMessageDelivered(onMessageDelivered);

    mqttClient.onSubscribe(onSubscribe);
    mqttClient.onUnsubscribe(onUnsubscribe);

    mqttClient.connect();

    setClient(mqttClient);
  }

  // ------------------ END of MQTT Client ------------------

  const handleDisconnectClick = () => {
    client.disconnect();

    setConnected(false);
    setClient(null);
    setSubscriptions({});
  }

  const handleSubscribeClick = () => {
    addSubscription(subscribeTopic);
  }

  const handlePublishClick = () => {
    addInfo(`Publishing message: "${message}" to topic: "${topic}"`);
    client.publish(topic, message);
  }

  const handleGetRoboListClick = () => {
    addSubscription('robo/list');
    client.publish('robo/list/get', '');
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <Typography variant="h3"> <Logo width={"48"} height={"48"} sx={{ mt: 1, pt: 2 }} onClick={() => { navigate('/dev/mqtt') }} /> MQTT Playground</Typography>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">Connection</Typography>
            </Grid>

            <Grid item lg={4} xs={12}>
              Connection URL
            </Grid>

            <Grid item lg={2} xs={3}>
              <Select fullWidth variant="filled" value={scheme} onChange={(e) => setScheme(e.target.value)} disabled={connected}>
                <MenuItem value="ws">ws://</MenuItem>
                <MenuItem value="wss">wss://</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6} lg={4}>
              <Autocomplete
                fullWidth
                variant="filled"
                freeSolo
                options={predefinedHosts}
                value={host}
                disabled={connected}
                onChange={(e, value) => setHost(value)}
                onBlur={(e) => setHost(e.target.value)}
                renderInput={(params) => (
                  <AutocompleteTextField {...params} variant="filled" sx={{ pt: -3 }} />
                )}
              />
            </Grid>
            <Grid item lg={2} xs={3}>
              <Select fullWidth variant="filled" value={port} onChange={(e) => setPort(e.target.value)} disabled={connected}>
                <MenuItem value="9001">9001</MenuItem>
                <MenuItem value="443">443</MenuItem>
              </Select>
            </Grid>


            <Grid item lg={4} xs={12}>
              DSN
            </Grid>
            <Grid item lg={7} xs={10} sx={{ overflow: 'clip' }}>
              <Typography
                variant="body2"
                sx={{
                  paddingTop: '7px',
                  transition: 'opacity 0.2s ease-in-out'
                }}
                style={{ opacity: copied ? '0.4' : '1' }}
              >
                {dsn}
              </Typography>
            </Grid>
            <Grid item lg={1} xs={2}>
              <IconButton onClick={() => copyToClipboard(dsn)}>
                <CopyIcon />
              </IconButton>
            </Grid>


            <Grid item lg={4} xs={12}>
              User/Password
            </Grid>
            <Grid item lg={4} xs={6}>
              <TextField fullWidth variant="filled" value={user} onChange={(e) => setUser(e.target.value)} disabled={connected} />
            </Grid>
            <Grid item lg={4} xs={6}>
              <TextField fullWidth variant="filled" value={password} onChange={(e) => setPassword(e.target.value)} disabled={connected} />
            </Grid>

            <Grid item lg={4} xs={12}>
            </Grid>
            <Grid item lg={4} xs={6}>
              <Button variant="contained" color="primary" onClick={handleConnectClick} disabled={connected} fullWidth>Connect</Button>
            </Grid>
            <Grid item lg={4} xs={6}>
              <Button variant="contained" color="error" onClick={handleDisconnectClick} disabled={!connected} fullWidth>Disconnect</Button>
            </Grid>

            <GridBreak />

            <Grid item xs={12}>
              <Typography variant="h4">Commands</Typography>
            </Grid>
            <Grid item xs={8}>
              <Button variant="contained" color="info" onClick={handleGetRoboListClick} disabled={!connected}>Get Robo List</Button>
            </Grid>

            {/* Multiclient */}
            {connected && <Grid item xs={4}>
              <MultiClientEditor robos={roboList} client={client} />
            </Grid>}

            <GridBreak />

            {!!roboList.length && <Grid item xs={12} container spacing={2}>
              {/* Iterate through robo list */}
              {roboList.map((robo, index) => (
                <React.Fragment key={robo}>
                  <Grid item xs={5}>
                    <Chip
                      avatar={<Logo width={"24"} height={"24"} variant={'live'} />}
                      label={`Robo ${robo}`}
                      variant="contained"
                      sx={{ height: '40px', textAlign: 'left', paddingLeft: '10px' }}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Button
                      variant="outlined"
                      color="success"
                      fullWidth
                      onClick={() => {
                        addSubscription(`robo/${robo}/transmit`);
                      }}
                      disabled={!connected}>Subscribe</Button>
                  </Grid>

                  <Grid item xs={2}>
                    <MessageEditor onSubmit={(value) => {
                      const endpoint = `robo/${robo}/receive`;
                      client.publish(endpoint, value);
                    }} />
                  </Grid>

                  <Grid item xs={2}>
                    <RoboClientEditor robo={robo} client={client} />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>}



            <Grid item xs={12}>
              <Typography variant="h4">Subscribe</Typography>
            </Grid>

            <Grid item xs={6}>
              Subscribe to Topic
            </Grid>
            <Grid item xs={3}>
              <TextField fullWidth variant="filled" value={subscribeTopic} onChange={(e) => setSubscribeTopic(e.target.value)} />
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" color="success" onClick={handleSubscribeClick} disabled={!connected} fullWidth>Subscribe</Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Subscriptions</Typography>
            </Grid>
            <Grid item xs={12}>
              <List>
                {Object.keys(subscriptions).length === 0 && <ListItem><ListItemText primary="No subscriptions" /></ListItem>}

                {Object.keys(subscriptions).map((topic, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={topic} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => removeSubscription(topic)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>



            <Grid item xs={12}>
              <Typography variant="h4">Publish</Typography>
            </Grid>

            <Grid item xs={6}>
              Topic
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth variant="filled" value={topic} onChange={(e) => setTopic(e.target.value)} />
            </Grid>

            <Grid item xs={6}>
              Message
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth variant="filled" value={message} onChange={(e) => setMessage(e.target.value)} />
            </Grid>

            <Grid item xs={2}>
              <Button variant="contained" color="success" onClick={handlePublishClick} disabled={!connected}>Publish</Button>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4">Connection parameters</Typography>
            </Grid>

            <Grid item xs={6}>
              Reconnect
            </Grid>
            <Grid item xs={6}>
              <Switch checked={reconnect} onChange={(e) => setReconnect(e.target.checked)} />
            </Grid>

            <Grid item xs={6}>
              Clean Session
            </Grid>
            <Grid item xs={6}>
              <Switch checked={cleanSession} onChange={(e) => setCleanSession(e.target.checked)} />
            </Grid>

            <Grid item xs={6}>
              Reconnect Interval, Milliseconds
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth variant="filled" value={reconnectInterval} onChange={(e) => setReconnectInterval(e.target.value)} />
            </Grid>

            <Grid item xs={6}>
              Max Reconnect Attempts
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth variant="filled" value={maxReconnectAttempts} onChange={(e) => setMaxReconnectAttempts(e.target.value)} />
            </Grid>

            <Grid item xs={6}>
              Timeout, Seconds
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth variant="filled" value={timeout} onChange={(e) => setTimeout(e.target.value)} />
            </Grid>

            <Grid item xs={6}>
              Keep Alive Interval, Seconds
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth variant="filled" value={keepAliveInterval} onChange={(e) => setKeepAliveInterval(e.target.value)} />
            </Grid>

          </Grid>
        </Grid>


        <Grid item xs={12} lg={6}>

          <Grid container spacing={2} >

            <Grid item xs={8}>
              <Typography variant="h4">Events</Typography>
            </Grid>

            <Grid item xs={4}>
              <FormControlLabel control={<Switch checked={enableEvents} onChange={(e) => setEnableEvents(e.target.checked)} />} label="Enable events" />
            </Grid>

            {enableEvents && (<>
              <Grid item xs={2}>
                <Button variant="contained" fullWidth color="error" sx={{ zIndex: 9999 }} onClick={clearEvents} disabled={!Object.keys(events).length}>Clear</Button>
              </Grid>

              <Grid item xs={2}></Grid>

              <Grid item xs={4}>
                <FormControlLabel control={<Switch checked={enableRichEvents} onChange={(e) => setEnableRichEvents(e.target.checked)} />} label="Rich events" />
              </Grid>

              <Grid item xs={4}>
                <FormControlLabel control={<Switch checked={renderEvents} onChange={(e) => setRenderEvents(e.target.checked)} />} label="Render events" />
              </Grid>

              {/* Iterate through events */}
              {enableEvents && renderEvents && enableRichEvents && events && Object.keys(events).reverse().map((key, index) => (
                <Grid item xs={12} key={key}>
                  <Alert severity={events[key].type} icon={events[key].icon}>
                    {events[key].title && <AlertTitle><Typography variant='x-body-bold'>{events[key].title}</Typography></AlertTitle>}
                    {events[key].date.toLocaleTimeString('en-US', { hour12: false })}&nbsp;-&nbsp;{events[key].message}
                    {events[key].extra}
                  </Alert>
                </Grid>
              ))}

              {enableEvents && renderEvents && !enableRichEvents && events && Object.keys(events).reverse().map((key, index) => (
                <Grid item xs={12} key={key}>
                  [{events[key].type}] - {events[key].date.toLocaleTimeString('en-US', { hour12: false })} - {events[key].message}
                </Grid>
              ))}

              {enableEvents && !renderEvents && (
                <Grid item xs={12}>
                  <Alert severity="warning">
                    <Typography variant="body2">Events are collecting. Rendering disabled</Typography>
                  </Alert>
                </Grid>
              )}

            </>)}

            {!enableEvents && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  <Typography variant="body2">Events are disabled</Typography>
                </Alert>
              </Grid>
            )}

          </Grid>
        </Grid>

      </Grid>


    </Container >
  )
}

export default MqttPage
