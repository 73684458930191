import React from 'react'
import { Router } from '@reach/router'

import { PrivateRoute } from '@lib/route'

import Layout from '@dev/layouts/layout'

import Seo from '@components/seo'

import Dashboard from '@dev/pages/dashboard'
import ApiCompare from '@dev/pages/api-compare'
import Mqtt from '@dev/pages/mqtt'
import Playground from '@dev/pages/playground'
import RoboLive from '@dev/pages/robo-live'
import RoboCode from '@dev/pages/robo-code'
import RoboBlockly from '@dev/pages/robo-blockly'

const DevPage = () => {
  return (
    <Layout>
      <Seo seo={{ metaTitle: 'Dev Playground' }} />

      <Router basepath="/dev" style={{ height: '100%' }}>

        <PrivateRoute path="/" component={Dashboard} />
        <PrivateRoute path="/api-compare" component={ApiCompare} />
        <PrivateRoute path="/mqtt" component={Mqtt} />
        <PrivateRoute path="/playground" component={Playground} />
        <PrivateRoute path="/robo-live" component={RoboLive} />
        <PrivateRoute path="/robo-code" component={RoboCode} />
        <PrivateRoute path="/robo-blockly" component={RoboBlockly} />

      </Router>
    </Layout>
  )
}

export default DevPage;
