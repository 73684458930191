import React from 'react';

import { Grid, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function CommandsQueue({ queue, removeCommandFromQueue, handleRun }) {
    return (<>
        {!!queue.length && <Table sx={{ mt: 1, mb: 1 }} size='small'>
            <TableHead>
                <TableRow>
                    <TableCell><Typography variant='x-xsmall-bold'> Target </Typography></TableCell>
                    <TableCell><Typography variant='x-xsmall-bold'> Payload </Typography></TableCell>
                    <TableCell><Typography variant='x-xsmall-bold'> Times </Typography></TableCell>
                    <TableCell><Typography variant='x-xsmall-bold'> Offset </Typography></TableCell>
                    <TableCell><Typography variant='x-xsmall-bold'> Interval </Typography></TableCell>
                    <TableCell><Typography variant='x-xsmall-bold'> Actions </Typography></TableCell>

                </TableRow>
            </TableHead>

            <TableBody>
                {queue.map((queueMessage) => (
                    <TableRow key={queueMessage.id}>
                        <TableCell> <Typography variant='x-xsmall-bold'> {queueMessage.client.name}</Typography></TableCell>
                        <TableCell> <Typography variant='x-xsmall-regular'>{queueMessage.command} {queueMessage.data} </Typography></TableCell>
                        <TableCell> <Typography variant='x-xsmall-regular'> {queueMessage.runTimes} </Typography></TableCell>
                        <TableCell> <Typography variant='x-xsmall-regular'> {queueMessage.runOffset} </Typography></TableCell>
                        <TableCell> <Typography variant='x-xsmall-regular'> {queueMessage.runInterval} </Typography></TableCell>
                        <TableCell>
                            <Button onClick={() => removeCommandFromQueue(queueMessage.id)} variant="filled" size='small'>
                                <DeleteIcon />
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>}

        {!!queue.length && <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button onClick={() => handleRun(queue)} fullWidth variant="contained" color='success'> Run Queue </Button>
            </Grid>
        </Grid>}
    </>
    );
}

export default CommandsQueue;
