import React, { useState, useContext, useEffect } from 'react';

import { Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { CommandsQueueContext } from '@dev/providers/commands-queue-context';

const CUSTOM_CLIENT_ID = -1;
const EMPTY_CLIENT_ID = -2;

const CommandOptions = ({ clients = [], target = { id: EMPTY_CLIENT_ID }, setTarget, runTimes, setRunTimes, runInterval, setRunInterval, runOffset, setRunOffset, allowCustomClient = false }) => {
    const {
        txTopic, setTxTopic,
        rxTopic, setRxTopic,
        isCustomClient, setIsCustomClient,

        setIsValid
    } = useContext(CommandsQueueContext);

    const handleRunTimesChange = (event) => {
        setRunTimes(event.target.value);
    };

    const handleRunIntervalChange = (event) => {
        setRunInterval(event.target.value);
    };

    const handleTargetClientChange = (event) => {
        const targetClient = clients.find((client) => client.id === event.target.value);

        // If custom client is selected
        if (event.target.value === CUSTOM_CLIENT_ID) {
            setTarget({ id: CUSTOM_CLIENT_ID });
        }
        // If 'Please Select' is selected
        else if (event.target.value === EMPTY_CLIENT_ID) {
            setTarget({ id: EMPTY_CLIENT_ID });
        }
        else {
            setTarget(targetClient);
        }

        setTxTopic('');
        setRxTopic('');

        setIsCustomClient(event.target.value === CUSTOM_CLIENT_ID);
    };

    const handleRunOffsetChange = (event) => {
        setRunOffset(event.target.value);
    };

    const handleTxTopicChange = (event) => {
        setTxTopic(event.target.value);
    };

    const handleRxTopicChange = (event) => {
        setRxTopic(event.target.value);
    };

    useEffect(() => {

        if (!target) {
            setIsValid(false);
        } else if (target?.id === EMPTY_CLIENT_ID) {
            setIsValid(false);
        } else if (target?.id === CUSTOM_CLIENT_ID) {
            setIsValid(!!rxTopic);
        } else {
            setIsValid(true);
        }
    }, [target, rxTopic, setIsValid]);

    return (
        <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={2}>
                <Typography variant='x-xsmall-bold'> Run times </Typography>
            </Grid>

            <Grid item xs={2}>
                <Typography variant='x-xsmall-bold'> Run offset, ms </Typography>
            </Grid>

            <Grid item xs={3}>
                <Typography variant='x-xsmall-bold'> Run interval, ms </Typography>
            </Grid>

            <Grid item xs={5}>
                <Typography variant='x-xsmall-bold'> Target </Typography>
            </Grid>


            <Grid item xs={2}>
                <TextField type='number' variant='outlined' size='small' fullWidth value={runTimes} onChange={handleRunTimesChange} />
            </Grid>

            <Grid item xs={2}>
                <TextField type='number' variant='outlined' size='small' fullWidth value={runOffset} onChange={handleRunOffsetChange} />
            </Grid>

            <Grid item xs={3}>
                <TextField type='number' variant='outlined' size='small' fullWidth value={runInterval} onChange={handleRunIntervalChange} />
            </Grid>

            <Grid item xs={5}>
                <Select variant='outlined' size='small' fullWidth value={target?.id || EMPTY_CLIENT_ID} disabled={!clients.length && !allowCustomClient} onChange={handleTargetClientChange}>
                    <MenuItem key={EMPTY_CLIENT_ID} value={EMPTY_CLIENT_ID} disabled>
                        Please Select
                    </MenuItem>

                    {clients.map((client) => (
                        <MenuItem key={client.id} value={client.id}>
                            {client.name}
                        </MenuItem>
                    ))}

                    {allowCustomClient && <MenuItem key={CUSTOM_CLIENT_ID} value={CUSTOM_CLIENT_ID} title='Custom client'>Custom client</MenuItem>}
                </Select>
            </Grid>

            {isCustomClient && (
                <>
                    <Grid item xs={6}>
                        <TextField type='text' variant='outlined' size='small' fullWidth
                            value={rxTopic} onChange={handleRxTopicChange} placeholder="Send to Topic" />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField type='text' variant='outlined' size='small' fullWidth
                            value={txTopic} onChange={handleTxTopicChange} placeholder="Listen to Topic" />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default CommandOptions;
