import React from 'react'

import { Grid, Typography } from '@mui/material'

import { Link } from 'gatsby'

const Dashboard = () => {
  return (
    <>
      <Typography variant="h1">Development</Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Link to={'/dev/playground'}>Development Playground</Link>
        </Grid>

        <Grid item xs={12}>
          <Link to={'/dev/api-compare'}>REST and GraphQL APIs compare</Link>
        </Grid>

        <Grid item xs={12}>
          <Link to={'/dev/mqtt'}>MQTT WebApp Playground</Link>
        </Grid>

        <Grid item xs={12}>
          <Link to={'/dev/robo-live'}>Robo Live</Link>
        </Grid>

        <Grid item xs={12}>
          <Link to={'/dev/robo-code'}>Robo Code</Link>
        </Grid>

        <Grid item xs={12}>
          <Link to={'/dev/robo-blockly'}>Robo Blockly</Link>
        </Grid>
      </Grid>
    </>
  )
}

export default Dashboard;
