import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import { QueueMessage } from '@dev/components/commands-editor'

export const CommandsQueueContext = createContext();

export function CommandsQueueProvider({ children }) {
    const [commandsQueue, setCommandsQueue] = useState([]);

    const [txTopic, setTxTopic] = useState('');
    const [rxTopic, setRxTopic] = useState('');

    const [isCustomClient, setIsCustomClient] = useState(false);

    const [isValid, setIsValid] = useState(true);

    const addCommandToQueue = (roboClient, command, data, runTimes, runOffset, runInterval) => {
        const queueMessage = new QueueMessage(roboClient, command, data, runTimes, runOffset, runInterval);
        setCommandsQueue([...commandsQueue, queueMessage]);
    };

    const removeCommandFromQueue = (id) => {
        setCommandsQueue(commandsQueue.filter((queueMessage) => queueMessage.id !== id));
    };

    return (
        <CommandsQueueContext.Provider value={{
            commandsQueue,
            setCommandsQueue,
            addCommandToQueue,
            removeCommandFromQueue,

            txTopic, setTxTopic,
            rxTopic, setRxTopic,
            isCustomClient, setIsCustomClient,

            isValid, setIsValid,
        }}>
            {children}
        </CommandsQueueContext.Provider>
    );
}

CommandsQueueProvider.propTypes = {
    children: PropTypes.node.isRequired,
};