import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Button, Grid } from '@mui/material';

import { CommandsQueueContext } from '@dev/providers/commands-queue-context'

function QueuedActions({ handleClose, handleRun, handleAddToQueue }) {
  const {
    isValid
  } = useContext(CommandsQueueContext);

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={3}>
        <Button onClick={handleClose} fullWidth variant="outlined"> Cancel </Button>
      </Grid>

      <Grid item xs={4}>
        <Button onClick={handleAddToQueue} fullWidth variant="outlined" color='success' disabled={!isValid}> Add to Queue </Button>
      </Grid>

      <Grid item xs={5}>
        <Button onClick={handleRun} fullWidth variant='contained' disabled={!isValid}> Run </Button>
      </Grid>
    </Grid>
  );
}

QueuedActions.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleRun: PropTypes.func.isRequired,
  handleAddToQueue: PropTypes.func.isRequired,
};

export default QueuedActions;