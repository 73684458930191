import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const MqttPlaygroundContext = createContext();

export function MqttPlaygroundProvider({ children }) {
    const [enableEvents, setEnableEvents] = useState(true);
    const [enableRichEvents, setEnableRichEvents] = useState(true);
    const [renderEvents, setRenderEvents] = useState(true);

    return (
        <MqttPlaygroundContext.Provider value={{
            enableEvents, setEnableEvents,
            enableRichEvents, setEnableRichEvents,
            renderEvents, setRenderEvents
        }}>
            {children}
        </MqttPlaygroundContext.Provider>
    );
}

MqttPlaygroundProvider.propTypes = {
    children: PropTypes.node.isRequired,
};