import React from 'react'

import BaseLayout from '@layouts/layout'
import { MqttPlaygroundProvider } from '@dev/providers/mqtt-playground-context'

export default function Layout({ children }) {
  return (
    <BaseLayout>
      <MqttPlaygroundProvider>
        {children}
      </MqttPlaygroundProvider>
    </BaseLayout>
  )
}
