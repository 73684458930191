/**
 * Convert a string representation of a hex value to its numerical counterpart.
 * For instance, "0x61" => 0x61.
 * @param {string} hexString - The hex string to convert (e.g., "0x61").
 * @returns {number} - The numerical hex value (e.g., 0x61).
 */
export const convertStringRepresentationToHex = (hexString) => {
    if (typeof hexString === 'string' && /^0x[0-9a-fA-F]+$/.test(hexString)) {
        return parseInt(hexString, 16);
    }

    return NaN;
}

/**
 * Convert a numerical hex value to its string representation.
 * For instance, 0x01 => "0x01".
 * @param {number} hexNumber - The hex number to convert (e.g., 0x01).
 * @returns {string} - The string representation (e.g., "0x01").
 */
export const convertHexToStringRepresentation = (hexNumber) => {
    if (typeof hexNumber === 'number') {
        let hexString = hexNumber.toString(16);

        // Ensure the hex string has at least two characters after the "0x" prefix
        hexString = hexString.length % 2 ? '0' + hexString : hexString;

        return "0x" + hexString.toUpperCase();
    }
    return false;
}