import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, ToggleButton, ToggleButtonGroup, Grid, Button, Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const Uint8ArrayTable = ({ data, columns = 10, extractText = false }) => {
  const rows = Math.ceil(data.length / columns);
  const [format, setFormat] = useState('hex');
  const [fontSize, setFontSize] = useState(14);

  const getCellValue = (row, col) => {
    const index = row * columns + col;
    if (index < data.length) {
      switch (format) {
        case 'dec': return data[index];
        case 'bin': return data[index].toString(2).padStart(8, '0');
        case 'txt': return String.fromCharCode(data[index]);
        default: return <span title={data[index].toString(2).padStart(8, '0')} style={{ fontWeight: data[index] ? 'bold' : 'inherit' }}>0x{data[index].toString(16).padStart(2, '0').toUpperCase()}</span>;
      }
    }
    return null;
  }

  const extractString = (array) => {
    return String.fromCharCode.apply(null, array);
  }

  const handleFormat = (event, newFormat) => {
    if (newFormat !== null) {
      setFormat(newFormat);
    }
  };

  const handleFontSize = (increase) => {
    setFontSize(prevFontSize => increase ? prevFontSize + 1 : Math.max(prevFontSize - 1, 1));
  };

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={6}>
        <ToggleButtonGroup
          color="primary"
          value={format}
          exclusive
          size='small'
          onChange={handleFormat}
          fullWidth

        >
          <ToggleButton value="hex">HEX</ToggleButton>
          <ToggleButton value="dec">DEC</ToggleButton>
          <ToggleButton value="bin">BIN</ToggleButton>
          <ToggleButton value="txt">TXT</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={6} sx={{ justifyContent: "right", display: 'flex' }}>
        <IconButton size="small" onClick={() => handleFontSize(false)}>
          <RemoveIcon fontSize="small" />
        </IconButton>
        <IconButton size="small" onClick={() => handleFontSize(true)}>
          <AddIcon fontSize="small" />
        </IconButton>
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table" sx={{ '& td, & th': { fontFamily: 'Courier New, monospace', fontSize: `${fontSize}px` } }}>
            <TableHead>
              <TableRow>
                {Array(columns).fill().map((_, i) => (
                  <TableCell key={i}>{i + 1}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array(rows).fill().map((_, row) => (
                <TableRow key={row}>
                  {Array(columns).fill().map((_, col) => (
                    <TableCell key={col}>{getCellValue(row, col)}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {extractText &&
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="body1" style={{ fontFamily: 'Courier New, monospace', fontSize: `${fontSize}px` }}>{extractString(data)}</Typography>
          </Paper>
        </Grid>
      }
    </Grid>
  );
}

export default Uint8ArrayTable;
