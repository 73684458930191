import React, { useState } from 'react';

import { Button, TextareaAutosize, ToggleButton, ToggleButtonGroup, Alert, IconButton, Collapse, Grid } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';

import Hex from '../hex';

const HexEditor = ({ data, setData, error, setError }) => {
    const [infoOpen, setInfoOpen] = useState(false);
    const [text, setText] = useState('');
    const [mode, setMode] = useState('hex');

    const handleModeChange = (event, newMode) => {
        if (newMode !== null) {
            setMode(newMode);
            setText('');
            setData(new Uint8Array());
        }
    };

    const handleTextChange = (event) => {
        let newText = event.target.value;
        setText(newText);

        // Check for errors and convert input to Uint8Array
        const parts = newText.replace(/\n/g, ' ').split(' ');
        const arr = [];
        let hasError = false;

        if (mode === 'txt') {
            arr.push(...newText.split('').map(ch => ch.charCodeAt(0)));
        } else {
            for (let part of parts) {
                if (mode === 'hex') {
                    if (part.startsWith('0x')) part = part.slice(2);
                    let num = parseInt(part, 16);
                    if (isNaN(num) || num < 0 || num > 255) hasError = true;
                    arr.push(num);
                } else if (mode === 'dec') {
                    let num = parseInt(part, 10);
                    if (isNaN(num) || num < 0 || num > 255) hasError = true;
                    arr.push(num);
                } else {
                    arr.push(...[...part].map(ch => ch.charCodeAt(0)));
                }
            }
        }

        setError(hasError);
        setData(new Uint8Array(arr));
    };

    return (
        <>
            <ToggleButtonGroup
                color="primary"
                value={mode}
                exclusive
                size='small'
                onChange={handleModeChange}
                sx={{ mb: 1 }}
            >
                <ToggleButton value="hex">HEX</ToggleButton>
                <ToggleButton value="dec">DEC</ToggleButton>
                <ToggleButton value="raw">RAW</ToggleButton>
                <ToggleButton value="txt">TXT</ToggleButton>
            </ToggleButtonGroup>

            <IconButton
                size="small"
                onClick={() => setInfoOpen(!infoOpen)}
                sx={{ mb: 1 }}
            >
                <InfoIcon />
            </IconButton>

            <Collapse in={infoOpen}>
                <Alert severity="info">
                    For HEX input, type space separated hex numbers  (0x00 to 0xFF). For DEC input, type space separated decimal numbers
                    (0 to 255). For RAW input, type any text. For TXT input, type plain text (each character will be converted to its corresponding ASCII code). Invalid input will be highlighted.
                </Alert>
            </Collapse>

            <TextareaAutosize
                minRows={3}
                placeholder="Enter data here..."
                value={text}
                onChange={handleTextChange}

                style={{ width: '100%', marginBottom: '1rem', backgroundColor: error ? '#ffcccc' : 'white' }}
            />

            <Hex data={data} columns={10} />

        </>
    );
};

export default HexEditor;
