import React from 'react'

import { Typography } from '@mui/material'
import MotionVerticalSlider from '@webapp/components/blocks/widgets/motor-slider/motion-vertical-slider'

const RoboLive = () => {
  return (
    <>
      <Typography variant="h1">Robo Live</Typography>
      <MotionVerticalSlider w="98px" h="398px" widgetNumber={1} />
    </>
  )
}

export default RoboLive
