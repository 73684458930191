import React from 'react'

import { Typography } from '@mui/material'

const PlaygroundPage = () => {
  return (
    <>
      <Typography variant="h1">Playground</Typography>
    </>
  )
}

export default PlaygroundPage
