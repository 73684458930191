import React, { useState } from 'react';

import { Button, Popover, Box, Backdrop, Grid } from '@mui/material';

import { HexEditor } from './commands-editor';

const MessageEditor = ({ onSubmit }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const [data, setData] = useState(new Uint8Array());
    const [error, setError] = useState(false);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (!error) {
            onSubmit(data);
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button variant="outlined" fullWidth onClick={handleClick}>
                Raw
            </Button>

            <Backdrop open={open}>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}

                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Box p={2} maxWidth={500}>
                        <form onSubmit={handleFormSubmit}>
                            <HexEditor data={data} setData={setData} error={error} setError={setError} />

                            <Grid container spacing={2} sx={{ mt: 1 }}>
                                <Grid item xs={4}>
                                    <Button onClick={handleClose} fullWidth variant="outlined">
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={8}>
                                    <Button type="submit" fullWidth variant="contained" color="primary">
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Popover>
            </Backdrop>
        </>
    );
};

export default MessageEditor;

