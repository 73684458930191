import React, { useState, useContext, useEffect } from 'react';

import { Grid, Select, MenuItem, TextareaAutosize, Typography, TextField, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Commands } from '@lib/robo/commands';
import Hex from '../hex';

import { convertHexToStringRepresentation, convertStringRepresentationToHex } from '@lib/utils/hex'

import { CommandOptions, QueuedActions, CommandsQueue, Presets, HexEditor } from './index';

import { CommandsQueueContext } from '@dev/providers/commands-queue-context'
import { RoboClient } from '@lib/robo/robo-client';

const defaultCommand = Commands.CMD_PLAY_SOUND.code;
const defaultText = '';
const defaultData = new Uint8Array();

const TYPE_BUILDER = "commands-builder"
const TYPE_RAW = "raw"

function CommandsEditor({ buildCommand, showPresets, showQueue, showCommandOptions, showActions, title, clients, targetClient, handleClose, mqttClient, allowCustomClient = false }) {
    const [error, setError] = useState(false);

    // switcher for editor types
    const [commandType, setCommandType] = useState(TYPE_BUILDER);

    // For commands builder
    const [command, setCommand] = useState(defaultCommand);
    const [data, setData] = useState(defaultData);
    const [text, setText] = useState(defaultText);

    // For raw editor
    const [rawCommand, setRawCommand] = useState("");

    // Command parameters
    const [runTimes, setRunTimes] = useState(1);
    const [runInterval, setRunInterval] = useState(100);
    const [runOffset, setRunOffset] = useState(0);

    const [target, setTarget] = useState(targetClient);

    const {
        commandsQueue,
        addCommandToQueue,
        removeCommandFromQueue,

        isCustomClient,
        txTopic,
        rxTopic
    } = useContext(CommandsQueueContext);

    const handleTextChange = (event) => {
        let newText = event.target.value;
        updateText(newText);
    }

    const handleCommandTypeChange = (commandBuilderType) => (event, isExpanded) => {
        if (isExpanded) {
            setCommandType(commandBuilderType);
        } else {
            if (commandBuilderType === TYPE_RAW) {
                setCommandType(TYPE_BUILDER);
            } else {
                setCommandType(TYPE_RAW);
            }
        }
    }

    const handleSelectCommand = (event) => {
        setCommand(event.target.value);
    }

    const handleAddToQueue = () => {
        let currentTarget = target;

        if (isCustomClient) {
            currentTarget = createFakeTarget(txTopic, rxTopic);
        }

        if (commandType === TYPE_BUILDER) {
            console.log('A')
            addCommandToQueue(currentTarget, command, data, runTimes, runOffset, runInterval);
        } else {
            console.log('B')
            addCommandToQueue(currentTarget, null, rawCommand, runTimes, runOffset, runInterval);
        }
    }

    const handleRunMultipleTimes = () => {
        runMultipleTimes(handleRun, runTimes, runOffset, runInterval);
    }

    const createFakeTarget = (transmitTopic, receiveTopic) => {
        console.log('creating fake client', transmitTopic, receiveTopic)
        return new RoboClient(mqttClient, -1, { transmitTopic, receiveTopic });
    }

    const runMultipleTimes = (handler, runTimes, runOffset, runInterval) => {
        let counter = 0;

        setTimeout(() => {
            handler();
            counter++;

            if (counter >= runTimes) return;

            const intervalId = setInterval(() => {
                handler();
                counter++;

                if (counter >= runTimes) {
                    clearInterval(intervalId);
                }
            }, runInterval);


        }, runOffset);
    }

    const handleRunQueue = (queue) => {
        for (let queueMessage of queue) {
            const target = queueMessage.client;

            console.log('Sending command to ', target)
            console.log('Command: ', queueMessage.command)
            console.log('Data: ', queueMessage.data)

            runMultipleTimes(() => {
                if (queueMessage.command) {
                    target.sendCommand(queueMessage.command, queueMessage.data);
                } else {
                    console.log('Sending data')
                    target.sendData(queueMessage.data);
                }
            }, queueMessage.runTimes, queueMessage.runOffset, queueMessage.runInterval);
        }
    }

    const handleRun = () => {
        let currentTarget = target;

        if (isCustomClient) {
            currentTarget = createFakeTarget(txTopic, rxTopic);
        }

        if (commandType === TYPE_BUILDER) {
            currentTarget.sendCommand(command, data);
        } else {
            currentTarget.sendData(rawCommand);
        }
    }

    const handleCustomCommandChange = (event) => {
        const newText = event.target.value;

        console.log('New text', newText, 'converted to hex')

        try {
            const newCommand = convertStringRepresentationToHex(newText);

            console.log('Setting command', newCommand)
            setCommand(newCommand);
        } catch (e) {
            console.error(e);
        }
    }

    const updateText = (newText) => {
        setText(newText);

        if (newText === '') {
            setError(false);
            setData(defaultData);
            return;
        }

        // Check for errors and convert input to Uint8Array
        const parts = newText.replace(/\n/g, ' ').split(' ');
        const arr = [];

        let hasError = false;

        for (let part of parts) {
            if (part.startsWith('0x')) part = part.slice(2);
            let num = parseInt(part, 16);
            if (isNaN(num) || num < 0 || num > 255) {
                hasError = true;
            } else {
                arr.push(num);
            }
        }

        setError(hasError);

        if (arr.length) {
            setData(new Uint8Array(arr));
        } else {
            setData(defaultData);
        }
    }


    const printHex = (data) => {
        let hex = data.toString(16);
        hex = (hex.length === 1) ? '0' + hex : hex; // Ensuring two digits
        return <Typography style={{ fontWeight: 'bold', display: 'inline' }}>0x{hex.toUpperCase()}</Typography>;
    }

    const printCommand = (command) => {
        const commandPrefix = "CMD_";
        const commandName = command.replace(commandPrefix, "");

        return (
            <Typography style={{ display: 'inline' }}>
                {commandPrefix}
                <span style={{ fontWeight: 'bold' }}>{commandName}</span>
            </Typography>
        );
    }

    return (<>
        {/* EDITOR */}
        <Grid container spacing={2}>
            {!!title && <Grid item xs={12}>
                <Typography variant='x-headline4-semibold'>{title}</Typography>
            </Grid>}

            <Grid item xs={12}>
                <Accordion expanded={commandType === 'commands-builder'} onChange={handleCommandTypeChange('commands-builder')}
                    sx={{ borderLeft: '3px solid #ffcccc' }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id="commands-builder"
                    >
                        <Typography variant='x-body-bold'> Commands builder </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <TextField
                                    variant='outlined'
                                    value={convertHexToStringRepresentation(command)}
                                    onChange={handleCustomCommandChange}
                                />
                            </Grid>

                            <Grid item xs={10}>
                                <Select
                                    value={command}
                                    onChange={handleSelectCommand}
                                    fullWidth
                                >
                                    {Object.keys(Commands).map((key) => (
                                        <MenuItem key={key} value={Commands[key].code} title={Commands[key].description}>
                                            {printHex(Commands[key].code)}&nbsp;-&nbsp;{printCommand(key)} &nbsp; - &nbsp;
                                            {Commands[key].send ? '↑' : ''}
                                            {Commands[key].receive ? '↓' : ''}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>

                            <Grid item xs={12}>
                                <TextareaAutosize
                                    minRows={3}
                                    placeholder="Enter ONLY payload here..."
                                    value={text}
                                    onChange={handleTextChange}

                                    style={{
                                        width: '100%',
                                        maxWidth: '100%',
                                        minWidth: '100%',
                                        height: '48px',
                                        minHeight: '48px',
                                        backgroundColor: error ? '#ffcccc' : 'white',
                                        borderRadius: '8px',
                                        padding: '8px',
                                        borderColor: error ? 'red' : '#ccc',
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sx={{ paddingTop: '0 !important' }} >
                                <Hex data={buildCommand ? buildCommand(command, data) : defaultData} columns={10} />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={commandType === 'raw'} onChange={handleCommandTypeChange('raw')} sx={{ borderLeft: '3px solid #ffcccc' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id="raw"
                    >
                        <Typography variant='x-body-bold'> Raw command </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <HexEditor data={rawCommand} setData={setRawCommand} error={error} setError={setError} />
                    </AccordionDetails>
                </Accordion>
            </Grid>



        </Grid >

        {/* COMMAND OPTIONS */}
        {
            showCommandOptions &&
            <CommandOptions
                clients={clients}
                target={target}
                allowCustomClient={allowCustomClient}

                setTarget={setTarget}
                runTimes={runTimes}
                setRunTimes={setRunTimes}
                runInterval={runInterval}
                setRunInterval={setRunInterval}
                runOffset={runOffset}
                setRunOffset={setRunOffset}
            />
        }

        {/* ACTION BUTTONS */}
        {showActions && <QueuedActions handleAddToQueue={handleAddToQueue} handleClose={handleClose} handleRun={handleRunMultipleTimes} />}

        {/* QUEUE */}
        {showQueue && <CommandsQueue queue={commandsQueue} removeCommandFromQueue={removeCommandFromQueue} handleRun={handleRunQueue} />}

        {/* PRESETS */}
        {showPresets && <Presets setCommand={setCommand} updateText={updateText} />}
    </>
    );
}

export default CommandsEditor;
