import React, { useState } from 'react';
import PropTypes from 'prop-types'

import { Button, Popover, Box, Backdrop, Chip } from '@mui/material';

import { RoboClient } from '@lib/robo/robo-client';
import { CommandsEditor } from './commands-editor';
import { CommandsQueueProvider } from '@dev/providers/commands-queue-context'

import { Logo } from '@components/Logo/Logo';

const RoboClientEditor = ({ robo, client }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [roboClient, setRoboClient] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

        if (!roboClient) {
            setRoboClient(new RoboClient(client, robo));
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const buildCommand = (command, data) => {
        try {
            return RoboClient.staticBuildCommand(command, data);
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    return (
        <CommandsQueueProvider>
            <Button variant="outlined" fullWidth onClick={handleClick}>
                Client
            </Button>

            <Backdrop open={open}>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}

                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}

                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                >
                    <Box p={2} maxWidth={600} width={600}>
                        <CommandsEditor
                            title={
                                <Chip
                                    avatar={<Logo width={"24"} height={"24"} variant={'live'} />}
                                    label={`Robo ${robo}`}
                                    variant="contained"
                                    sx={{ height: '40px', textAlign: 'left', paddingLeft: '10px' }}
                                />
                            }

                            clients={[roboClient]}
                            targetClient={roboClient}

                            buildCommand={buildCommand}

                            showPresets={true}
                            showQueue={true}
                            showCommandOptions={true}
                            showActions={true}

                            handleClose={handleClose}
                        />
                    </Box>
                </Popover>
            </Backdrop>
        </CommandsQueueProvider>

    );
};

// proptypes
RoboClientEditor.propTypes = {
    robo: PropTypes.string.isRequired,
    client: PropTypes.object.isRequired
};

export default RoboClientEditor;
